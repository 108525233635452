.tooltipClass {
	min-width: 650px;
}

.difclass {
	min-width: 450px;
    background-color: #FFF4CF;
}

.noShowIntroOverlay{
	display: none;
}

/* .introjs-overlay {
	
	z-index: 0;
	} */

/* .introjs-helperLayer {
	z-index: 0;
		} */