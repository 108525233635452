@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden;
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
	body {
		background-color: white;
		color: black;
	}
}

@media screen and (max-width: 680px) {
	body {
		overflow-y: auto;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body.overflow {
	overflow: hidden;
}

#__lpform_input_idx_0_numspan {
	display: none;
}

.Toastify__toast-body {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	height: fit-content;
	padding: 2px;
}

.Toastify__toast--dark {
	width: 30rem;
	background: #3c3c3c;
	color: #fff;
}

.Toastify__toast-container--top-right {
	top: 1em;
	display: flex;
	right: 1.7rem !important;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
}

.Toastify__toast-container--bottom-right {
	width: 30rem;
	display: flex;
	right: 1.7rem !important;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
}

.Toastify__toast {
	width: fit-content;
	height: fit-content;
	min-height: 0 !important;
	font-size: 12px;
	margin-bottom: 5px !important;
	margin: 0 0 0.5rem 35% !important;
}

.Toastify__toast--dark {
	width: fit-content;
	height: fit-content;
	min-height: 0 !important;
	font-size: 12px;
	margin-bottom: 5px !important;
	margin: 0 0 0.5rem 35% !important;
	background: #3c3c3c !important;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast {
		margin: 0 auto !important;
	}
}

.Toastify__toast-body {
	max-width: 10rem;
	padding: 0 1.2rem 0 0.3rem !important;
}

.recomm-toast {
	width: 5rem;
	height: auto;
	height: auto;
}
.full-view {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	width: calc(50%);
	box-sizing: border-box; 
	overflow-y: auto;
	
  }

  .full-view	::-webkit-scrollbar {
	display: none;
}
