@font-face {
	font-family: 'Times New Roman';
	src: url('../fonts/TimesNRCyrMT.eot');
	src: url('../fonts/TimesNRCyrMT.eot?#iefix') format('embedded-opentype'),
		url('../fonts/TimesNRCyrMT.woff2') format('woff2'),
		url('../fonts/TimesNRCyrMT.woff') format('woff'),
		url('../fonts/TimesNRCyrMT.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
	font-family: 'Arial', sans-serif;
	content: 'Arial';
}
span.ql-font-Arial {
	font-family: 'Arial', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value='Helvetica']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Helvetica']::before {
	font-family: 'Helvetica', sans-serif;
	content: 'Helvetica';
}
span.ql-font-Helvetica {
	font-family: 'Helvetica', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value='Gill-Sans']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Gill-Sans']::before {
	font-family: 'Gill Sans', sans-serif;
	content: 'Gill Sans';
}
span.ql-font-Gill-Sans {
	font-family: 'Gill Sans', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lucida']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lucida']::before {
	font-family: 'Lucida', sans-serif;
	content: 'Lucida';
}
span.ql-font-Lucida {
	font-family: 'Lucida', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value='Helvetica-Narrow']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Helvetica-Narrow']::before {
	font-family: 'Helvetica Narrow', sans-serif;
	content: 'Helvetica Narrow';
}
span.ql-font-Helvetica-Narrow {
	font-family: 'Helvetica Narrow', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before,
.ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before {
	font-family: 'sans-serif', cursive;
	content: 'sans-serif';
}
span.ql-font-sans-serif {
	font-family: 'sans-serif', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='Times']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Times']::before {
	font-family: 'Times', cursive;
	content: 'Times';
}
span.ql-font-Times {
	font-family: 'Times', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Times-New-Roman']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Times-New-Roman']::before {
	font-family: 'Times New Roman', cursive;
	content: 'Times New Roman';
}
span.ql-font-Times-New-Roman {
	font-family: 'Times New Roman', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Palatino']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Palatino']::before {
	font-family: 'Palatino', cursive;
	content: 'Palatino';
}
span.ql-font-Palatino {
	font-family: 'Palatino', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Bookman']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Bookman']::before {
	font-family: 'Bookman', cursive;
	content: 'Bookman';
}
span.ql-font-Bookman {
	font-family: 'Bookman', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='New-Century-Schoolbook']::before,
.ql-picker.ql-font .ql-picker-item[data-value='New-Century-Schoolbook']::before {
	font-family: 'New-Century-Schoolbook', cursive;
	content: 'New-Century-Schoolbook';
}
span.ql-font-New-Century-Schoolbook {
	font-family: 'New-Century-Schoolbook', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
	font-family: 'serif', cursive;
	content: 'serif';
}
span.ql-font-serif {
	font-family: 'serif', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Andale-Mono']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Andale-Mono']::before {
	font-family: 'Andale-Mono', cursive;
	content: 'Andale-Mono';
}
span.ql-font-Andale-Mono {
	font-family: 'Andale-Mono', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='Courier-New']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Courier-New']::before {
	font-family: 'Courier New', cursive;
	content: 'Courier New';
}
span.ql-font-Courier-New {
	font-family: 'Courier New', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Courier']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Courier']::before {
	font-family: 'Courier', cursive;
	content: 'Courier';
}
span.ql-font-Courier {
	font-family: 'Courier', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lucidatypewriter']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lucidatypewriter']::before {
	font-family: 'Lucidatypewriter', cursive;
	content: 'Lucidatypewriter';
}
span.ql-font-Lucidatypewriter {
	font-family: 'Lucidatypewriter', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Fixed']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Fixed']::before {
	font-family: 'Fixed', cursive;
	content: 'Fixed';
}
span.ql-font-Fixed {
	font-family: 'Fixed', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
	font-family: 'monospace', cursive;
	content: 'monospace';
}
span.ql-font-monospace {
	font-family: 'monospace', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='Comic-Sans']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Comic-Sans']::before {
	font-family: 'Comic Sans', cursive;
	content: 'Comic Sans';
}
span.ql-font-Comic-Sans {
	font-family: 'Comic Sans', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='Zapf-Chancery']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Zapf-Chancery']::before {
	font-family: 'Zapf Chancery', cursive;
	content: 'Zapf Chancery';
}
.ql-picker.ql-font .ql-picker-label[data-value='Coronetscriptspan']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Coronetscript']::before {
	font-family: 'Coronetscript', cursive;
	content: 'Coronetscript';
}
span.ql-font-Coronetscript {
	font-family: 'Coronetscript', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='Florence']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Florence']::before {
	font-family: 'Florence', cursive;
	content: 'Florence';
}
.ql-picker.ql-font .ql-picker-label[data-value='Parkavenspan']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Parkavenue']::before {
	font-family: 'Parkavenue', cursive;
	content: 'Parkavenue';
}
span.ql-font-Parkavenue {
	font-family: 'Parkavenue', cursive;
}
.ql-picker.ql-font .ql-picker-label[data-value='cursive']::before,
.ql-picker.ql-font .ql-picker-item[data-value='cursive']::before {
	font-family: 'cursive', cursive;
	content: 'cursive';
}
span.ql-font-cursive {
	font-family: 'cursive', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Impact']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Impact']::before {
	font-family: 'Impact', cursive;
	content: 'Impact';
}
span.ql-font-Impact {
	font-family: 'Impact', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Arnoldboecklin']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Arnoldboecklin']::before {
	font-family: 'Arnoldboecklin', cursive;
	content: 'Arnoldboecklin';
}
span.ql-font-Arnoldboecklin {
	font-family: 'Arnoldboecklin', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Oldtown']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Oldtown']::before {
	font-family: 'Oldtown', cursive;
	content: 'Oldtown';
}
span.ql-font-Oldtown {
	font-family: 'Oldtown', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Blippo']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Blippo']::before {
	font-family: 'Blippo', cursive;
	content: 'Blippo';
}
span.ql-font-Blippo {
	font-family: 'Blippo', cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value='Brushstroke']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Brushstroke']::before {
	font-family: 'Brushstroke', cursive;
	content: 'Brushstroke';
}
.ql-picker.ql-font .ql-picker-label[data-value='fantasy']::span.ql-font-Brushstroke {
	font-family: 'Brushstroke', cursive;
}
before,
.ql-picker.ql-font .ql-picker-item[data-value='fantasy']::before {
	font-family: 'fantasy', cursive;
	content: 'fantasy';
}
span.ql-font-fantasy {
	font-family: 'fantasy', cursive;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before {
	content: attr(data-label);
}
